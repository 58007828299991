import { httpClient } from '@/expressway-api/httpClient'

const route = 'baskets'
const routeBuyerDetail = 'buyer-detail'

interface BuyerDetail {
  FirstName: string;
  LastName: string;
  Email: string;
  IsSendingInfoAllowed: boolean;
}

const buyerDetail = async (
  basketId: string,
  buyerDetail: BuyerDetail
): Promise<void> => {
  await httpClient.put(`${route}/${basketId}/${routeBuyerDetail}`, buyerDetail)
}

export { buyerDetail }
