/* eslint-disable camelcase */
interface ExponeaEvent {
  step: string;
  Timestamp?: string;
  basket_code?: string;
  departure_date?: string;
  destination_stop_city?: string;
  destination_stop_code?: string;
  destination_stop_country?: string;
  origin_stop?: string;
  origin_stop_city?: string;
  origin_stop_code?: string;
  origin_stop_country?: string;
  return_journey?: boolean;
  bus_company?: string;
  currency?: string;
  destination_stop?: string;
  line_brand?: string;
  arrival_time?: string;
  departure_time?: string;
  journey_destination_stop?: string;
  journey_origin_stop?: string;
  quantity?: number;
  arrival_date?: string;
  price?: number;
  seat_class?: string;
  email?: string;
  first_name?: string;
  last_name?: string;
}

const sendExponeaCheckoutEvent = (eventProperties: ExponeaEvent) => {
  // eslint-disable-next-line
  (window as any).dataLayer?.push({
    event: 'exponea-checkout',
    data: eventProperties
  })
}
const sendExponeaCustomerIdentificationEvent = (email: string, id?: number|null) => {
  // eslint-disable-next-line
  (window as any).dataLayer?.push({
    event: 'exponea-identify-customer',
    data: {
      email: email,
      id: id
    }
  })
}
const sendExponeaCustomerRegistered = (email: string) => {
  // eslint-disable-next-line
  (window as any).dataLayer?.push({
    event: 'exponea-registered-customer',
    data: {
      email: email
    }
  })
}
export {
  sendExponeaCheckoutEvent,
  sendExponeaCustomerIdentificationEvent,
  sendExponeaCustomerRegistered,
  ExponeaEvent
}
