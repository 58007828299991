
import { defineComponent } from 'vue'
import Modal from '@/components/Modal.vue'
import GenericError from '@/components/GenericError.vue'
import useBasket from '@/composables/useBasket'

export default defineComponent({
  components: { Modal, GenericError },
  setup () {
    const { renewBasketExpiry, canRenew, showExpiryWarning } = useBasket()
    const message = 'Time is running out to complete the booking. ' +
      'If you wish to continue with your booking please select \'CONTINUE\'.'

    const closeModal = () => { showExpiryWarning.value = false }

    return {
      message,
      renewBasketExpiry,
      canRenew,
      showExpiryWarning,
      closeModal
    }
  }
})
