
import { defineComponent, toRefs, ref, computed, watch } from 'vue'
import { email, required } from '@vuelidate/validators'
import { sameAs } from 'vuelidate/lib/validators'
import useVuelidate from '@vuelidate/core'
import { buyerState } from '@/composables/useBuyerDetails'
import useSessions from '@/composables/useSessions'
import { UserDetail } from '@/models/User'
import { getBuyerDetails } from '@/expressway-api/auth.api'
import Modal from '@/components/Modal.vue'
import Checkbox from '@/components/Checkbox.vue'
import Spinner from '@/components/Spinner.vue'
import ModalLink from '@/components/CMSModalLink.vue'
import InfoIcon from '@/components/vectors/Info.vue'

export default defineComponent({
  components: {
    Checkbox,
    Modal,
    Spinner,
    ModalLink,
    InfoIcon
  },
  emits: ['updateValidity'],
  setup (props, { emit }) {
    const loading = ref(false)
    const { isLoggedIn } = useSessions()
    const rules = {
      firstName: { required },
      lastName: { required },
      email: {
        required,
        email
      },
      termsAndConditions: { sameAs: sameAs(() => true) },
      marketingConsent: {}
    }
    const v$ = useVuelidate(rules, buyerState)
    const valid = computed((): boolean => v$.value.$invalid)

    watch(valid, () => {
      emit('updateValidity', v$.value.$invalid)
    })

    if (isLoggedIn.value) {
      loading.value = true
      getBuyerDetails().then((r: UserDetail) => {
        buyerState.firstName = r.FirstName
        buyerState.lastName = r.LastName
        buyerState.email = r.Email
        buyerState.marketingConsent = r.IsSendingInfoAllowed
        emit('updateValidity', v$.value.$invalid)
      }).finally(() => {
        loading.value = false
      })
    }

    return {
      ...toRefs(buyerState),
      v$,
      isLoggedIn,
      loading
    }
  }
})
