
import { defineComponent, PropType } from 'vue'
import BusIcon from '@/components/vectors/BusIcon.vue'
import JourneyArrow from '@/components/vectors/JourneyArrow.vue'

export default defineComponent({
  components: {
    BusIcon,
    JourneyArrow
  },
  props: {
    bearing: {
      type: String as PropType<'inbound' | 'outbound'>,
      default: 'outbound'
    },
    origin: String,
    destination: String
  }
})
