
import { defineComponent } from 'vue'
import AlertIcon from '@/components/vectors/AlertIcon.vue'
import { formatDate } from '@/helpers'

export default defineComponent({
  components: { AlertIcon },
  props: {
    message: String,
    buttonText: String,
    buttonFunction: Function,
    nextDate: String
  },
  setup (props) {
    const formattedDate = formatDate(props.nextDate)

    return {
      formattedDate
    }
  }
})
