
import { computed, defineComponent, ref, watch } from 'vue'
import BasketFooter from '@/components/BasketFooter.vue'
import BasketExpiryWarning from '@/components/BasketExpiryWarning.vue'
import PageHeader from '@/components/PageHeader.vue'
import BearingHeader from '@/components/BearingHeader.vue'
import GenericError from '@/components/GenericError.vue'
import Spinner from '@/components/Spinner.vue'
import Checkbox from '@/components/Checkbox.vue'
import QuantitySelect from '@/components/QuantitySelect.vue'
import useTrip from '@/composables/useTrip'
import useBasket from '@/composables/useBasket'
import { getAvailableAddons, setAddons } from '@/expressway-api/baskets.api'
import { formatPrice } from '@/helpers'
import { Addon, Addons } from '@/models/Addon'
import useSnackbar from '@/composables/useSnackbar'
import { AxiosError } from 'axios'
import { useRouter } from 'vue-router'
import { preventHistoryBack } from '@/utils/preventHistoryBack'
import { sendExponeaCheckoutEvent } from '@/helpers/exponeaHelper'

export default defineComponent({
  props: {
    basketId: {
      type: String
    }
  },
  components: {
    BasketFooter,
    BasketExpiryWarning,
    PageHeader,
    BearingHeader,
    GenericError,
    Spinner,
    Checkbox,
    QuantitySelect
  },
  setup (props) {
    const { trip, setTripAddons, clearTrip, getTripFromBasket, totalPrice } = useTrip()
    const {
      basketId: currentBasketId,
      getBasket,
      clearLocalBasket
    } = useBasket()
    const basketId = ref(props.basketId ?? currentBasketId)
    const addonState = ref<{ outbound: Addon[]; inbound: Addon[] }>()
    const error = ref()
    const loading = ref(true)
    const { setSnackbar } = useSnackbar()
    const router = useRouter()
    const submitting = ref(false)

    if (props.basketId) {
      clearTrip()
      clearLocalBasket()
      getBasket(basketId.value)
      getTripFromBasket(basketId.value)
      preventHistoryBack()
    }

    getAvailableAddons(basketId.value).then(
      ({ data: { Addons: { Outbound, Inbound } } }) => {
        addonState.value = {
          outbound: Outbound,
          inbound: Inbound
        }
      }
    ).catch(e => { error.value = e })
      .finally(() => { loading.value = false })

    const addons = computed<Addons>(() => ({
      Addons: {
        Outbound: addonState.value?.outbound ?? [],
        Inbound: addonState.value?.inbound ?? []
      }
    }))

    watch(addons, () => setTripAddons(addons.value))

    const origin = computed(
      () => trip.outboundJourney?.journey?.OriginCityName ||
      trip.outboundJourney?.journey?.OriginStopName
    )
    const destination = computed(() => trip.outboundJourney?.journey?.DestinationCityName ||
      trip.outboundJourney?.journey?.DestinationStopName)

    const pushExponeaEvent = () => {
      sendExponeaCheckoutEvent({
        step: 'selected-extras',
        basket_code: currentBasketId.value
      })
    }
    const submit = () => {
      if (!addonState.value) return

      submitting.value = true
      setAddons(basketId.value, addons.value).then(() => {
        pushExponeaEvent()
        router.push({ name: 'Confirm Journey' })
      }).catch((e: AxiosError) => { setSnackbar(e.message) })
        .finally(() => { submitting.value = false })
    }

    return {
      trip,
      addonState,
      error,
      loading,
      formatPrice,
      submit,
      submitting,
      origin,
      destination,
      totalPrice
    }
  }
})
