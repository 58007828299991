
import useVuelidate from '@vuelidate/core'
import { PropType, computed, defineComponent, toRef } from 'vue'
import { email, helpers, required } from '@vuelidate/validators'
import Input from '@/components/Input.vue'
import Select from '@/components/Select.vue'
import { fareStrings } from '@/models/FareClass'
import { Gender } from '@/models/Gender'
import { Nationality } from '@/models/Nationality'
import dayjs from 'dayjs'

export default defineComponent({
  emits: ['update:passenger'],
  components: {
    'v-input': Input,
    'v-select': Select
  },
  props: {
    index: {
      required: true,
      type: Number
    },
    passenger: {
      required: true,
      type: Object
    },
    disabled: {
      type: Boolean,
      default: false
    },
    international: {
      type: Boolean,
      default: false
    },
    genders: {
      type: Object as PropType<Gender[]>
    },
    nationalities: {
      type: Object as PropType<Nationality[]>
    }
  },
  setup (props) {
    const rules = {
      PassengerFirstName: {},
      PassengerLastName: {},
      Email: { email: helpers.withMessage('Please enter a valid Email Address', email) },
      DateOfBirth: {},
      Gender: {},
      NationalityId: {}
    }
    const minimumDate = dayjs('1900-01-01').format('YYYY-MM-DD')
    const maximumDate = dayjs().format('YYYY-MM-DD')

    if (props.international) {
      rules.PassengerFirstName = { required }
      rules.PassengerLastName = { required }
      rules.DateOfBirth = {
        required,
        minValue: helpers.withMessage(
          () => `Please select a date after ${dayjs('1900-01-01').format('ddd, D MMM YYYY')}`,
          value => (value as string) >= dayjs('1900-01-01').toDate().toISOString().substring(0, 10)
        ),
        maxValue: helpers.withMessage(
          () => `Please select a date before ${dayjs().format('ddd, D MMM YYYY')}`,
          value => (value as string) <= dayjs().toDate().toISOString().substring(0, 10)
        )
      }
      rules.Gender = { required }
      rules.NationalityId = { required }
    }

    const fareClassString = fareStrings[props.passenger.FareClass]

    const passengerTitle = computed(() =>
      ({
        'Free Travel Seat': 'Seat only reservation (Free travel scheme)',
        'Reserved Seat': 'Seat only reservation (Valid ticket required)'
      } as Record<string, string>)[fareClassString] || fareClassString
    )

    return {
      passengerTitle,
      maximumDate,
      minimumDate,
      v$: useVuelidate(rules, toRef(props, 'passenger'))
    }
  }
})
