
import RadioButton from '@/components/vectors/RadioButton.vue'
import Wheelchair from './vectors/Wheelchair.vue'
import InfoIcon from '@/components/vectors/Info.vue'
import { defineComponent, ref, PropType, computed } from 'vue'
import Modal from '@/components/Modal.vue'
import ModalLink from '@/components/CMSModalLink.vue'
import { passengerTypes, Fares, faresComplete } from '@/models/FareClass'

export default defineComponent({
  name: 'WheelchairFareClassSelector',
  props: {
    passengersOnTrip: {
      type: Object as PropType<Fares>,
      required: true
    }
  },
  emits: ['selected'],
  components: {
    Modal,
    ModalLink,
    Wheelchair,
    InfoIcon,
    RadioButton
  },
  setup (props, { emit }) {
    const selected = ref('')

    const activeFares = computed(() => {
      const result = {} as faresComplete
      Object.entries(props.passengersOnTrip).forEach(passenger => {
        const [key, value]: [string, number | undefined] = passenger
        if (value && value > 0) {
          result[key] = passengerTypes[key]
        }
      })
      return result
    })

    const select = () => {
      if (selected.value !== '') {
        emit('selected', selected.value)
      }
    }
    return {
      select,
      selected,
      activeFares
    }
  }
})
