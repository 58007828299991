import { httpClient } from './httpClient'
import { Gender } from '@/models/Gender'

const route = '/genders'

const getGenders = async (): Promise<Gender[]> =>
  httpClient
    .get(route)
    .then(response => response.data.Genders.map(gender => (
      {
        name: gender.Text,
        value: gender.Value
      }
    )))

export {
  getGenders
}
