
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  nextTick
} from 'vue'
import useSwrv, { mutate } from 'swrv'
import useBasket from '@/composables/useBasket'
import { getJourneysForTravelPass } from '@/expressway-api/travelPasses.api'
import BasketFooter from '@/components/BasketFooter.vue'
import JourneyFeed from '@/components/JourneyFeed.vue'
import PageHeader from '@/components/PageHeader.vue'
import GenericError from '@/components/GenericError.vue'
import Spinner from '@/components/Spinner.vue'
import { PriceInfo } from '@/models/Journey'
import router from '@/router'
import useTrip from '@/composables/useTrip'
import { journeySubheading } from '@/helpers'
import useSnackbar from '@/composables/useSnackbar'
import { Fares, urlEncodeFares } from '@/helpers/fares'
import dayjs from 'dayjs'

export default defineComponent({
  props: {
    departureDate: {
      type: String,
      required: true
    },
    returnDate: String,
    originId: {
      type: Number,
      required: true
    },
    destinationId: {
      type: Number,
      required: true
    },
    isOutbound: {
      type: Boolean,
      default: true
    },
    isPartOfRoundTrip: {
      type: Boolean,
      default: false
    },
    fares: {
      type: Object as PropType<Fares>,
      required: true
    },
    travelPassNumber: {
      type: Number,
      required: true
    }
  },
  components: { BasketFooter, JourneyFeed, GenericError, Spinner, PageHeader },
  // eslint-disable-next-line max-lines-per-function
  setup (props) {
    const { setSnackbar } = useSnackbar()
    const onBasketError = (error: Error) => { setSnackbar(error.message) }
    const { confirmBasket, isSetting: settingBasket, clearBasket } = useBasket(onBasketError)
    const activeIndex = ref()
    const selectedPrice = ref<PriceInfo>()
    const { trip, updateTripJourney, clearTrip, pricingPassengers } = useTrip()

    clearTrip()

    const returnParams = {
      ...props,
      departureDate: props.returnDate || props.departureDate,
      returnDate: '',
      destinationId: props.originId,
      originId: props.destinationId,
      isOutbound: false,
      isPartOfRoundTrip: true
    }

    const { data: journeys, error } = useSwrv(
      () => `/journeys/search-by-travelpass/${JSON.stringify(props)}`,
      () => getJourneysForTravelPass(props)
    )

    const filteredJourneys = computed(() =>
      journeys.value?.filter(journey =>
        props.isOutbound ||
        dayjs(journey.DepartureDateTime) >= dayjs(trip.outboundJourney?.journey.ArrivalDateTime)
      )
    )

    const selectedJourney = computed(() => filteredJourneys.value?.[activeIndex.value])
    const subheading = computed(() =>
      selectedJourney.value && journeySubheading(selectedJourney.value))

    watch(filteredJourneys, journeys => {
      activeIndex.value = journeys?.findIndex(j => j.IsForSale)

      nextTick(() => {
        const [activeJourneyCard] = document.getElementsByClassName('active journey card')
        activeJourneyCard?.scrollIntoView({ behavior: 'smooth', block: 'end' })
      })
    })

    watch(selectedJourney, journey => {
      if (!journey) return

      const newPrice = journey.PriceInfo.find(pi =>
        pi.PriceClassName === selectedPrice.value?.PriceClassName)
      selectedPrice.value = newPrice ?? journey.PriceInfo[0]
    })

    if (props.isOutbound) clearBasket()

    mutate(
      `/journeys/search-by-travelpass/${JSON.stringify(returnParams)}`,
      getJourneysForTravelPass(returnParams)
    )

    watch(props, () => { activeIndex.value = 0 })

    watch([selectedJourney, selectedPrice], () => {
      if (!selectedJourney.value || !selectedPrice.value) return

      updateTripJourney(props.isOutbound, {
        journey: selectedJourney.value,
        priceInfo: selectedPrice.value
      })
    })

    const nextStep = () => {
      if (!props.returnDate || !props.isOutbound) {
        confirmBasket(trip)
      } else {
        router.push({
          name: 'TravelPass Select Journey',
          query: {
            ...returnParams,
            returnDate: '',
            isOutbound: 'false',
            isPartOfRoundTrip: 'true',
            fares: urlEncodeFares(returnParams.fares)
          }
        })
      }
    }

    const goToTravelPassSearch = () =>
      router.push({
        name: 'TravelPass Journey Search',
        query: { travelPass: props.travelPassNumber }
      })

    return {
      filteredJourneys,
      error,
      settingBasket,
      subheading,
      activeIndex,
      selectedJourney,
      selectedPrice,
      nextStep,
      pricingPassengers,
      goToTravelPassSearch
    }
  }
})
