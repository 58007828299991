
import { computed, defineComponent, PropType, ref, toRefs } from 'vue'
import { Journey, PriceInfo } from '@/models/Journey'
import RadioButton from '@/components/vectors/RadioButton.vue'
import Tick from '@/components/vectors/Tick.vue'
import Cross from '@/components/vectors/Cross.vue'
import BusIcon from '@/components/vectors/BusIcon.vue'
import { formatPrice, formatDate, formatTime, calculateJourneyDiscountStatus } from '@/helpers'
import JourneyDetailedInfo from '@/components/JourneyDetailedInfo.vue'
import CheckLineIcon from '@/components/vectors/CheckLineIcon.vue'

const notForSaleReason = (NotForSaleReasonType: string): string => {
  const reasonMap: { [x: string]: string } = {
    SOLD_OUT: 'No seats available',
    SALES_DISABLED: 'Not currently available',
    DEPARTED: 'Departed',
    SALES_ENDED: 'Online sales ended',
    NO_WHEELCHAIR_AREA_AVAILABLE: 'No wheelchair space available',
    WHEELCHAIR_BOOKING_IS_NO_LONGER_AVAILABLE: 'Not enough time to book',
    SALES_CLOSED_DUE_OFFLINE_TERMINAL: 'Online sales closed',
    NOT_ENOUGH_SEATS: 'Not enough seats available',
    NOT_ALLOWED_FOR_TRAVEL_PASS: 'Travel Pass not valid'
  }

  return reasonMap[NotForSaleReasonType?.slice(20)]
}

export default defineComponent({
  components: {
    RadioButton,
    Tick,
    Cross,
    BusIcon,
    JourneyDetailedInfo,
    CheckLineIcon
  },
  props: {
    journey: {
      type: Object as PropType<Journey>,
      required: true
    },
    selected: {
      type: Boolean,
      default: false
    },
    selectedPrice: {
      type: Object as PropType<PriceInfo>,
      required: true
    },
    radioGroup: {
      type: String
    }
  },
  emits: ['update:selectedPrice'],
  setup (props) {
    const { journey } = toRefs(props)
    const modalOpen = ref(false)
    const showDetails = ref(false)
    const priceString = computed(() => {
      if (journey.value.PriceInfo.length === 0) return

      if (props.selected) return formatPrice(props.selectedPrice.Price)

      return formatPrice(journey.value.PriceInfo[0].Price)
    })

    const appliedDiscount = computed(() => calculateJourneyDiscountStatus(props.journey.Legs))

    return {
      appliedDiscount,
      showDetails,
      modalOpen,
      formatDate,
      formatTime,
      formatPrice,
      notForSaleReason,
      priceString
    }
  }
})
