
import { defineComponent, nextTick, ref, watch } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import BearingHeader from '@/components/BearingHeader.vue'
import BasketFooter from '@/components/BasketFooter.vue'
import PriceSummary from '@/components/PriceSummary.vue'
import Ticket from '@/components/Ticket.vue'
import BusIcon from '@/components/vectors/BusIcon.vue'
import JourneyArrow from '@/components/vectors/JourneyArrow.vue'
import useTrip from '@/composables/useTrip'
import { journeySubheading } from '@/helpers'
import useTabs from '@/composables/useTabs'
import { useRouter } from 'vue-router'
import BasketExpiryWarning from '@/components/BasketExpiryWarning.vue'
import useBasket from '@/composables/useBasket'
import { sendExponeaCheckoutEvent } from '@/helpers/exponeaHelper'

export default defineComponent({
  name: 'ConfirmJourney',
  components: {
    PageHeader,
    BearingHeader,
    BasketFooter,
    PriceSummary,
    Ticket,
    BusIcon,
    JourneyArrow,
    BasketExpiryWarning
  },
  setup () {
    const { trip } = useTrip()
    if (!trip.outboundJourney) throw Error('No outbound journey in trip')
    const { activeTab, nextTab, previousTab } = useTabs(Object.keys(trip), 'outboundJourney')
    const tabBar = ref<HTMLElement>()
    const subheading = journeySubheading(
      trip.outboundJourney.journey,
      !!trip.inboundJourney
    )
    const router = useRouter()
    const {
      basketId: currentBasketId
    } = useBasket()
    const pushExponeaEvent = () => {
      sendExponeaCheckoutEvent(
        {
          step: 'confirmed-summary',
          basket_code: currentBasketId.value
        }
      )
    }
    const nextStep = () => {
      pushExponeaEvent()
      router.push({ name: 'Trip Buyer Details' })
    }

    watch(activeTab, () => {
      nextTick(() => {
        const activeTabButton = tabBar.value?.querySelector(
          '.active'
        ) as HTMLElement
        activeTabButton?.focus()
      })
    })

    return {
      trip: {
        outboundJourney: trip.outboundJourney,
        inboundJourney: trip.inboundJourney
      },
      activeTab,
      nextTab,
      previousTab,
      tabBar,
      subheading,
      nextStep
    }
  }
})
