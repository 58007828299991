
import { defineComponent, PropType, ref, computed } from 'vue'
import { LegDetailed } from '@/models/Journey'
import ArrowDown from './vectors/ArrowDown.vue'

export default defineComponent({
  components: {
    ArrowDown
  },
  props: {
    leg: {
      type: Object as PropType<LegDetailed>,
      required: true
    },
    tabindex: {
      type: Number,
      required: true
    }
  },
  setup (props) {
    const expanded = ref(false)
    const filteredStops = computed(() => {
      if (expanded.value) return props.leg.IntermediateStops

      return props.leg.IntermediateStops.slice(0, 3)
    })
    return { expanded, filteredStops }
  }
})
