
import { defineComponent } from 'vue'
import Modal from '@/components/Modal.vue'

export default defineComponent({
  name: 'WheelchairModal',
  emits: ['closeConfirmWheelChairModal', 'closeWheelChairModal'],
  components: { Modal },
  props: {
    showModal: {
      type: Boolean,
      default: false
    }
  }
})
