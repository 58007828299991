
import { defineComponent } from 'vue'
import ClearButton from '@/components/vectors/ClearButton.vue'

export default defineComponent({
  components: { ClearButton },
  inheritAttrs: false,
  props: { modelValue: String },
  emits: ['update:modelValue']
})
