
import BasketExpiryWarning from '@/components/BasketExpiryWarning.vue'
import BasketFooter from '@/components/BasketFooter.vue'
import PageHeader from '@/components/PageHeader.vue'
import PaymentDetails from '@/components/PaymentDetails.vue'
import Spinner from '@/components/Spinner.vue'
import useBasket from '@/composables/useBasket'
import useTrip from '@/composables/useTrip'
import { defineComponent, ref } from 'vue'
import { sendExponeaCheckoutEvent } from '@/helpers/exponeaHelper'

export default defineComponent({
  components: {
    BasketExpiryWarning,
    BasketFooter,
    PaymentDetails,
    PageHeader,
    Spinner
  },
  setup () {
    const { totalPrice } = useTrip()
    const { basketId, payWithZeroPaymentMethod } = useBasket()
    const disabled = ref(false)
    const loading = ref(false)
    const paymentDetailRef = ref<InstanceType<typeof PaymentDetails>>()
    const paymentNeeded = ref(false)
    const pushExponeaEvent = () => {
      sendExponeaCheckoutEvent({
        step: 'payment-process-init',
        price: totalPrice.value
      })
    }
    const sendPayment = () => {
      pushExponeaEvent()
      paymentDetailRef.value?.sendPayment('Trip Payment Confirmation')
    }

    if (totalPrice.value === 0) {
      payWithZeroPaymentMethod({
        name: 'Trip Payment Confirmation',
        params: { basketId: basketId.value }
      })
    } else {
      paymentNeeded.value = true
    }

    const resetForm = async () => {
      loading.value = false
    }

    return {
      basketId,
      disabled,
      loading,
      paymentDetailRef,
      paymentNeeded,
      resetForm,
      sendPayment
    }
  }
})
