import { httpClient } from './httpClient'
import { AmendJourney } from '@/models/Amends'
import { RefundOptions } from '@/models/Refund'

const amendOptionsRoute = '/amend/:processId/search-journeys'
const loadAmendRoute = '/amend/:processId/detail'
const addJourneyRoute = '/amend/:processId/add-journey'
const startAmendRoute = '/amend/start'
const cancelAmendRoute = '/amend/:processId/cancel'
const calculateRefundRoute = '/refunds/:basketId/calculate'
const commitRefundRoute = '/refunds/commit'
const initBookingAmend = (basketId: string, productCodes: string[]) => {
  const params = {
    BasketCode: basketId,
    ProductCodes: productCodes
  }
  return httpClient.post(startAmendRoute, params)
    .then(response => response.data)
}

const loadAmendJourneys = (
  isOutbound: boolean,
  amendProcessId: string,
  destinationBusStopId: number,
  originBusStopId: number,
  departureDate: string
) => {
  const route = amendOptionsRoute.replace(':processId', amendProcessId)
  const params = {
    DepartureDate: departureDate,
    IsOutbound: isOutbound,
    DestinationBusStopId: destinationBusStopId,
    OriginBusStopId: originBusStopId
  }
  return httpClient.get(route, { params })
    .then(res => {
      res.data.Journeys = res.data.Journeys.map((journey: AmendJourney) => (
        {
          ...journey,
          Id: journey.JourneyId,
          PriceInfo: journey.PriceClasses
        }))

      return res.data
    })
}

const loadAmend = async (amendProcessId: string) => {
  const route = loadAmendRoute.replace(':processId', amendProcessId)
  return await httpClient.get(route)
    .then(res => res.data)
}

const addJourneyToAmend = async (
  amendProcessId: string,
  journeyId: string,
  isOutbound: boolean,
  priceClass: string
) => {
  const route = addJourneyRoute.replace(':processId', amendProcessId)
  return await httpClient.post(route, {
    journeyId,
    direction: isOutbound ? 'Outbound' : 'Inbound',
    priceClass
  })
    .then(res => res.data)
}

const getCalculatedRefund = async (bookingId: string): Promise<RefundOptions> => {
  const route = calculateRefundRoute.replace(':basketId', bookingId)
  return await httpClient.get(route)
    .then(res => res.data)
}

const cancelCurrentAmend = async (amendProcessId: string) => {
  const route = cancelAmendRoute.replace(':processId', amendProcessId)
  return await httpClient.post(route)
    .then(res => res.data)
}

const commitRefund = async (bookingId: string, PassengerCodes: string[]) => {
  const refundableProducts = PassengerCodes.map(code => ({
    ProductCode: code,
    ReturningScopeXc: 'RETURNING_SCOPE.WHOLE_PRODUCT'
  }))
  return await httpClient.post(
    commitRefundRoute,
    {
      Id: bookingId,
      RefundableProducts: refundableProducts
    }
  )
    .then(res => res.data)
}

export {
  cancelCurrentAmend,
  loadAmendJourneys,
  loadAmend,
  addJourneyToAmend,
  initBookingAmend,
  getCalculatedRefund,
  commitRefund
}
