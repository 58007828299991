
import { computed, ComputedRef, defineComponent, inject } from 'vue'
import { PassengerGroup } from '@/models/Passenger'
import { calculateJourneyDiscountStatus } from '@/helpers'
import PassengerList from '@/components/PassengerList.vue'
import useTrip from '@/composables/useTrip'
import CheckLineIcon from '@/components/vectors/CheckLineIcon.vue'

export default defineComponent({
  components: { PassengerList, CheckLineIcon },
  props: {
    isOutbound: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { trip } = useTrip()

    const pricingPassengers = inject<Readonly<ComputedRef<PassengerGroup[]>>>('pricingPassengers')

    const passengers = computed(() =>
      pricingPassengers?.value?.find(p =>
        p.groupType === (props.isOutbound ? 'Outward' : 'Return')
      ) ?? []
    )
    const promoCodeMessage = computed(() => {
      if (props.isOutbound) {
        if (!trip.outboundJourney?.journey.Legs) return ''
        return calculateJourneyDiscountStatus(trip.outboundJourney.journey.Legs)
      }
      if (!trip.inboundJourney?.journey.Legs) return ''
      return calculateJourneyDiscountStatus(trip.inboundJourney.journey.Legs)
    })

    return {
      promoCodeMessage,
      trip,
      passengers,
      promoCode: inject('promoCode')
    }
  }
})
