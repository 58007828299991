
import { defineComponent, ref } from 'vue'
import BasketFooter from '@/components/BasketFooter.vue'
import { buyerDetail } from '@/expressway-api/buyDetails.api'
import useSnackbar from '@/composables/useSnackbar'
import useBasket from '@/composables/useBasket'
import BasketExpiryWarning from '@/components/BasketExpiryWarning.vue'
import router from '@/router'
import { buyerState } from '@/composables/useBuyerDetails'
import useTrip from '@/composables/useTrip'
import BuyerDetails from '@/components/BuyerDetails.vue'
import {
  sendExponeaCheckoutEvent,
  sendExponeaCustomerIdentificationEvent
} from '@/helpers/exponeaHelper'

export default defineComponent({
  components: {
    BasketFooter,
    BasketExpiryWarning,
    BuyerDetails
  },
  setup () {
    useTrip()
    const { setSnackbar } = useSnackbar()
    const onBasketError = (error: Error) => setSnackbar(error.message)
    const { basketId } = useBasket(onBasketError)
    const loading = ref(false)

    if (!basketId.value) throw Error('BasketId not found')

    const pushExponeaEvent = () => {
      sendExponeaCustomerIdentificationEvent(buyerState.email)
      sendExponeaCheckoutEvent({
        step: 'buyer-details',
        first_name: buyerState.firstName,
        last_name: buyerState.lastName,
        email: buyerState.email
      })
    }
    const disabled = ref(true)
    const buyerDetailsCall = async () => {
      try {
        loading.value = true
        await buyerDetail(basketId.value, {
          FirstName: buyerState.firstName,
          LastName: buyerState.lastName,
          Email: buyerState.email,
          IsSendingInfoAllowed: buyerState.marketingConsent
        })
        pushExponeaEvent()
        router.push({ name: 'Passenger Details' })
      } catch (e) {
        setSnackbar(e.message)
      }
      loading.value = false
    }

    return {
      loading,
      buyerDetailsCall,
      disabled
    }
  }
})
