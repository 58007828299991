import { reactive } from 'vue'

export const buyerState = reactive({
  firstName: '',
  lastName: '',
  email: '',
  dateOfBirth: '',
  gender: '',
  nationality: '',
  termsAndConditions: false,
  marketingConsent: false,
  emailFocus: false
})
