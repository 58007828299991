
import { defineComponent, PropType } from 'vue'
import { Journey } from '@/models/Journey'
import { formatDate, formatTime } from '@/helpers'

export default defineComponent({
  props: {
    journey: {
      type: Object as PropType<Journey>,
      required: true
    },
    direction: String
  },
  setup () {
    return { formatDate, formatTime }
  }
})
