<template>
  <svg
    viewBox="0 0 26 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1769 0.351562L10.9522 2.66892L19.7562 11.8562H0.554688V15.1432H19.7562L10.9522 24.3305L13.1769 26.6479L25.7991 13.4997L13.1769 0.351562Z"
      fill="#434343"
    />
  </svg>
</template>
