
import { defineComponent, reactive, ref, watch } from 'vue'
import BasketExpiryWarning from '@/components/BasketExpiryWarning.vue'
import Spinner from '@/components/Spinner.vue'
import GenericError from '@/components/GenericError.vue'
import PageHeader from '@/components/PageHeader.vue'
import { getBasketProducts, addPassengerDetails } from '@/expressway-api/baskets.api'
import useBasket from '@/composables/useBasket'
import { FareClass } from '@/models/FareClass'
import useVuelidate from '@vuelidate/core'
import PassengerDetailsFieldset from '@/components/PassengerDetailsFieldset.vue'
import Checkbox from '@/components/Checkbox.vue'
import BasketFooter from '@/components/BasketFooter.vue'
import { buyerState } from '@/composables/useBuyerDetails'
import useSnackbar from '@/composables/useSnackbar'
import router from '@/router'
import { AxiosError } from 'axios'
import useTrip from '@/composables/useTrip'
import { sendExponeaCheckoutEvent } from '@/helpers/exponeaHelper'
import { getCountries } from '@/expressway-api/countries.api'
import { getGenders } from '@/expressway-api/genders.api'
import { Nationality } from '@/models/Nationality'
import { Gender } from '@/models/Gender'

export default defineComponent({
  components: {
    BasketExpiryWarning,
    Spinner,
    GenericError,
    PageHeader,
    PassengerDetailsFieldset,
    BasketFooter,
    'v-checkbox': Checkbox
  },
  // eslint-disable-next-line max-lines-per-function
  setup () {
    useTrip()
    const { setSnackbar } = useSnackbar()
    const error = ref()
    const { basketId } = useBasket()
    const { isInternational } = useTrip()
    interface Passenger {
      ProductId: string;
      FareClass: FareClass;
      PassengerFirstName: string;
      PassengerLastName: string;
      Email: string;
      DateOfBirth: string;
      Gender: string;
      NationalityId: string;
    }
    const state = reactive({ passengers: [] as Passenger[] })
    const useBuyerDetails = ref(true)
    const setPassengerToBuyer = () => {
      state.passengers[0] = {
        ...state.passengers[0],
        PassengerFirstName: buyerState.firstName,
        PassengerLastName: buyerState.lastName,
        Email: buyerState.email,
        DateOfBirth: buyerState.dateOfBirth,
        Gender: buyerState.gender,
        NationalityId: buyerState.nationality
      }
    }
    const genderList = ref<Gender[]>([])
    const nationalityList = ref<Nationality[]>([])

    getBasketProducts(basketId.value)
      .then(response => {
        state.passengers = response.data?.JourneyProducts
          .map((product: { ProductId: string; RequestedFareClass: FareClass }) => ({
            ProductId: product.ProductId,
            FareClass: product.RequestedFareClass,
            PassengerFirstName: '',
            PassengerLastName: '',
            Email: '',
            DateOfBirth: '',
            Gender: '',
            NationalityId: ''
          }))
        if (useBuyerDetails.value) { setPassengerToBuyer() }
      })
      .catch(e => { error.value = e })

    watch(useBuyerDetails, value => {
      if (value) { setPassengerToBuyer() }
    })

    const sendingDetails = ref(false)

    const pushExponeaEvent = () => {
      sendExponeaCheckoutEvent(
        {
          step: 'passengers-details-added'
        }
      )
    }
    const sendDetails = () => {
      sendingDetails.value = true
      addPassengerDetails(
        basketId.value,
        state.passengers.map(p => ({ ...p, FareClass: undefined }))
      ).then(() => {
        pushExponeaEvent()
        router.push({ name: 'Trip Payment Details' })
      }).catch((e: AxiosError) => {
        setSnackbar(e.message)
      }).finally(() => {
        sendingDetails.value = false
      })
    }

    getGenders().then(response => {
      genderList.value = response
      genderList.value.unshift({ name: 'Choose gender', value: '' })
    })

    getCountries().then(response => {
      nationalityList.value = response.map(
        country => ({ name: country.name, value: country.value.toString() }))
      nationalityList.value.unshift({ name: 'Choose nationality', value: '' })
    })

    return {
      v$: useVuelidate(),
      useBuyerDetails,
      state,
      error,
      buyerState,
      sendDetails,
      sendingDetails,
      isInternational,
      genderList,
      nationalityList
    }
  }
})
