
import { defineComponent, PropType, ref, computed } from 'vue'
import { Journey, PriceInfo, LegDetailed } from '@/models/Journey'
import { getJourneyDetails } from '@/expressway-api/journeys.api'
import { formatPrice, formatDate, formatTimeDiff, formatTime } from '@/helpers'
import { PassengerGroup, PassengerGroupType } from '@/models/Passenger'
import { fareStrings } from '@/models/FareClass'
import Modal from '@/components/Modal.vue'
import Spinner from '@/components/Spinner.vue'
import PassengerList from '@/components/PassengerList.vue'
import LegsStops from './LegsStops.vue'

export default defineComponent({
  components: {
    Modal,
    Spinner,
    LegsStops,
    PassengerList
  },
  props: {
    journey: {
      type: Object as PropType<Journey>,
      required: true
    },
    selectedPrice: {
      type: Object as PropType<PriceInfo | undefined>,
      required: true
    }
  },
  setup (props) {
    const loadingLegs = ref(true)
    const legs = ref([] as LegDetailed[])
    getJourneyDetails(props.journey.Id)
      .then(res => {
        legs.value = res
      })
      .finally(() => {
        loadingLegs.value = false
      })
    const formatDuration = (duration: string) => {
      const durationArray = duration.split(':')
      return formatTimeDiff(Number(durationArray[0]), Number(durationArray[1]))
    }
    const pricingFormatted = computed(() => props.journey.Legs.map((leg, index) => {
      const group = {
        groupType: `Leg #${index + 1}`,
        passengers: []
      } as PassengerGroup
      if (!leg.Pricing) return group
      group.passengers = leg.Pricing.map(price => ({
        price: props.selectedPrice?.PriceClassName === 'Campaign'
          ? price.CampaignPriceLeg
          : price.RegularPriceLeg,
        passengerType: price.RequestedFareClass,
        passengerTypeDisplay: fareStrings[price.RequestedFareClass],
        quantity: price.Quantity,
        discounts: price.Discounts
      }))
      return group
    }))
    const total = computed(() => pricingFormatted.value.reduce(
      (sum: number, leg: PassengerGroup) => sum + leg.passengers.reduce(
        (sumPassengers: number, passenger: PassengerGroupType) =>
          sumPassengers + (passenger.price * passenger.quantity), 0)
      , 0)
    )
    return {
      pricingFormatted,
      loadingLegs,
      legs,
      formatDate,
      formatDuration,
      formatTime,
      formatPrice,
      total
    }
  }
})
