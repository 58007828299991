<template>
  <svg
    width="12"
    height="13"
    viewBox="2 0 14 13"
    fill="none"
    class="tick"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 6L5.33333 10L14.6667 2" stroke="#32827C" stroke-width="3" />
  </svg>
</template>
