
import { computed, defineComponent, PropType } from 'vue'
import Checkbox from '@/components/Checkbox.vue'

export default defineComponent({
  props: {
    id: {
      type: String,
      required: true
    },
    label: String,
    modelValue: {
      type: Array as PropType<string[]>,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data () {
    return {
      internalValue: this.modelValue
    }
  },
  components: { Checkbox },
  emits: ['update:modelValue'],
  setup (props, { emit }) {
    const checked = computed(() => props.modelValue.includes(props.value))
    const changedValue = (val: string[]) => {
      emit('update:modelValue', val)
    }
    const changeValue = () => {
      if (props.disabled) return

      let internalValue = [...props.modelValue]
      if (!checked.value) {
        internalValue.push(props.value)
      } else {
        internalValue = internalValue.filter(item => item !== props.value)
      }
      changedValue(internalValue)
    }
    return { checked, changeValue, changedValue }
  }
})
