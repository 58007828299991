
import { defineComponent, computed } from 'vue'
import PageHeader from '@/components/PageHeader.vue'
import useSessions from '@/composables/useSessions'
import PaymentConfirmation from '@/components/PaymentConfirmation.vue'
import useBasket from '@/composables/useBasket'

export default defineComponent({
  components: {
    PageHeader,
    PaymentConfirmation
  },
  props: {
    basketId: {
      type: String,
      required: true
    }
  },
  setup () {
    const { isLoggedIn } = useSessions()
    const { clearPaidBasket } = useBasket()
    const loggedBody =
      'Your tickets have been sent to your email and are also available in your account.'
    const notLoggedBody = 'Your tickets have been sent to your email.'
    const body = computed(() => isLoggedIn ? loggedBody : notLoggedBody)

    clearPaidBasket()

    return {
      body
    }
  }
})
