<template>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="#C2002"
    class="cross"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.90156 1.08698L8.91456 0.0999756L5.00156 4.01298L1.08856 0.0999756L0.101562 1.08698L4.01456 4.99998L0.101562 8.91298L1.08856 9.89998L5.00156 5.98698L8.91456 9.89998L9.90156 8.91298L5.98856 4.99998L9.90156 1.08698Z"
    />
  </svg>
</template>
