
import { defineComponent, PropType, computed } from 'vue'
import { RefundableProduct } from '@/models/Refund'
import { fareStrings } from '@/models/FareClass'
import { formatPrice } from '@/helpers'

export default defineComponent({
  props: {
    passenger: {
      type: Object as PropType<RefundableProduct>,
      required: true
    },
    passengersSelected: {
      type: Array as PropType<string[]>,
      required: true
    }
  },
  setup (props) {
    const isSelected = computed(() =>
      props.passengersSelected.some(passenger =>
        passenger === props.passenger.ProductCode
      )
    )

    const passengerName = computed(() => props.passenger.PassengerName.trim() || 'Passenger')
    return {
      passengerName,
      isSelected,
      fareStrings,
      formatPrice
    }
  }
})
