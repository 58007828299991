import { httpClient } from './httpClient'
import { Nationality } from '@/models/Nationality'

const route = '/countries'

const getCountries = async (): Promise<Nationality[]> =>
  httpClient
    .get(route)
    .then(response => response.data.Countries.map(country => (
      {
        name: country.Name,
        value: country.Id
      }
    )))

export {
  getCountries
}
